import React, { useState } from 'react';
import './Dashboard.css';

const Dashboard: React.FC = () => {
    const [formData, setFormData] = useState({
        childName: '',
        age: '',
        departureAddress: '',
        arrivalAddress: '',
        departureTime: '',
        arrivalTime: '',
        nameRefDepart: '',
        phoneRefDepart: '',
        phoneRefArriver: '',
        nameRefArriver: '',
        serviceName: '',
        comment: "",
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        console.log('Formulaire soumis :', formData);
    };

    return (
        <div className="reservation-page">
            <h1>Réservation de Courses</h1>
            <form onSubmit={handleSubmit}>
                <div className="form-flex">
                    <div>
                        <div className="input-group">
                            <label>
                                <i className="fas fa-child"></i> Nom de l'enfant :
                            </label>
                            <input
                                type="text"
                                name="childName"
                                value={formData.childName}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="input-group">
                            <label>
                                <i className="fas fa-child"></i> Age de l'enfant :
                            </label>
                            <input
                                type="number"
                                name="age"
                                value={formData.age}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <label style={{marginLeft: 10, fontSize: 15}}>
                            <i className="fa-solid fa-plus"></i> Ajouter un enfant
                        </label>
                        <div className="input-group">
                            <label>
                                <i className="fas fa-map-marker-alt"></i> Adresse de départ :
                            </label>
                            <input
                                type="text"
                                name="departureAddress"
                                value={formData.departureAddress}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div className="input-group">
                            <label>
                                <i className="fas fa-map-marker-alt"></i> Adresse d'arrivée :
                            </label>
                            <input
                                type="text"
                                name="arrivalAddress"
                                value={formData.arrivalAddress}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div className="input-group">
                            <label>
                                <i className="fas fa-clock"></i> Heure de départ :
                            </label>
                            <input
                                type="time"
                                name="departureTime"
                                value={formData.departureTime}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div className="input-group">
                            <label>
                                <i className="fas fa-clock"></i> Heure d'arrivée :
                            </label>
                            <input
                                type="time"
                                name="arrivalTime"
                                value={formData.arrivalTime}
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>
                    <div>
                        <div className="input-group">
                            <label>
                                <i className="fa-solid fa-list-ol"></i> Nombre d'enfant :
                            </label>
                            <input
                                type="number"
                                name="nameRefDepart"
                                value={formData.nameRefDepart}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="input-group">
                            <label>
                                <i className="fa-solid fa-user-large"></i> Nom du référent - départ :
                            </label>
                            <input
                                type="text"
                                name="nameRefDepart"
                                value={formData.nameRefDepart}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="input-group">
                            <label>
                                <i className="fa-solid fa-mobile-screen"></i> Numéro du référent - départ :
                            </label>
                            <input
                                type="number"
                                name="phoneRefDepart"
                                value={formData.phoneRefDepart}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="input-group">
                            <label>
                                <i className="fa-solid fa-user-large"></i> Nom du référent - arrivée :
                            </label>
                            <input
                                type="text"
                                name="nameRefArriver"
                                value={formData.nameRefArriver}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="input-group">
                            <label>
                                <i className="fa-solid fa-mobile-screen"></i> Numéro du référent - arrivée :
                            </label>
                            <input
                                type="number"
                                name="phoneRefArriver"
                                value={formData.phoneRefArriver}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="input-group">
                            <label>
                                <i className="fa-solid fa-school-flag"></i> Nom du service :
                            </label>
                            <input
                                type="text"
                                name="serviceName"
                                value={formData.serviceName}
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>
                </div>
                <div className="input-group">
                    <label>
                        <i className="fa-solid fa-comment-dots"></i> Commentaire :
                    </label>
                    <input
                        type="text"
                        name="comment"
                        value={formData.comment}
                        onChange={handleChange}
                        required
                    />
                </div>
                <button type="submit" className="submit-btn">
                    <i className="fas fa-check-circle"></i> Réserver
                </button>
            </form>
        </div>
    );
};

export default Dashboard;