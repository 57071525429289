import React from 'react';
import {BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';
import {AuthProvider} from './context/AuthContext';
import LoginPage from './pages/LoginPage';
import Dashboard from './pages/Dashboard';
import ProtectedRoute from './components/ProtectedRoute';
import Sidebar from "./components/Sidebar";
import './App.css';
import TrackingPage from "./pages/TrackingPage";

const App: React.FC = () => {

    const fakeData = [
        {
            childName: 'John Doe',
            age: '8',
            departureAddress: '123 Rue Principale, Paris',
            arrivalAddress: '456 Rue Secondaire, Paris',
            departureTime: '08:30',
            arrivalTime: '12:45',
            nameRefDepart: 'Marie Dupont',
            phoneRefDepart: '0123456789',
            phoneRefArriver: '0987654321',
            nameRefArriver: 'Jean Martin',
            serviceName: 'TAD SEINE AVAL',
            comment: "Pas d'allergies, aime lire des livres durant le trajet.",
            status: 'Arrived'
        },
        {
            childName: 'Jane Smith',
            age: '10',
            departureAddress: '78 Avenue des Champs, Paris',
            arrivalAddress: '45 Boulevard de la Liberté, Paris',
            departureTime: '09:00',
            arrivalTime: '13:30',
            nameRefDepart: 'Paul Durand',
            phoneRefDepart: '0176543210',
            phoneRefArriver: '0981234567',
            nameRefArriver: 'Sylvie Bernard',
            serviceName: 'TAD SEINE AVAL',
            comment: 'Enfant calme, préfère écouter de la musique.',
            status: 'Shipped'
        },
        {
            childName: 'Tommy Lee',
            age: '6',
            departureAddress: '45 Rue des Lilas, Versailles',
            arrivalAddress: '89 Avenue de la République, Versailles',
            departureTime: '10:00',
            arrivalTime: '14:30',
            nameRefDepart: 'Claire Dupuis',
            phoneRefDepart: '0987654321',
            phoneRefArriver: '0176543210',
            nameRefArriver: 'Marc Petit',
            serviceName: 'TAD GRAND VERSAILLES',
            comment: 'Aime jouer aux jeux vidéo pendant le trajet.',
            status: 'Packaging'
        },
        // Ajoute plus d'entrées ici si nécessaire
    ];


    return (
        <AuthProvider>
            <Router>
                <div className="app-container">
                    <Routes>
                        <Route path="/login" element={<LoginPage/>}/>
                        <Route
                            path="/dashboard"
                            element={
                                <ProtectedRoute>
                                    <Sidebar/>
                                    <Dashboard/>
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/tracking"
                            element={
                                <ProtectedRoute>
                                    <Sidebar/>
                                    <TrackingPage formDataList={fakeData}/>
                                </ProtectedRoute>
                            }
                        />
                        <Route path="*" element={<Navigate to="/login" replace/>}/>
                    </Routes>
                </div>
            </Router>
        </AuthProvider>
    );
};

export default App;