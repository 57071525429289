import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import './LoginPage.css';
import logo from "../assets/logo3.png";
import {useAuth} from "../context/AuthContext";

const LoginPage: React.FC = () => {
    const [formData, setFormData] = useState({username: '', password: ''});
    const [error, setError] = useState('');
    const auth = useAuth();
    const navigate = useNavigate();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log(e);
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        const {username, password} = formData;

        const isAuthenticated = auth.login(username, password);
        if (isAuthenticated) {
            navigate('/dashboard');
        } else {
            setError('Nom d’utilisateur ou mot de passe incorrect');
        }
    };


    return (
        <div className="login-container">
            <img src={logo} alt="Logo" className="login-logo"/>
            <div className="login-box">
                <h1>Connexion</h1>
                <form onSubmit={handleSubmit}>
                    <div className="input-group">
                        <label htmlFor="email">E-mail</label>
                        <input
                            type="email"
                            id="username"
                            name="username"
                            placeholder="Nom d'utilisateur"
                            value={formData.username}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="input-group">
                        <label htmlFor="password">Mot de passe</label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            placeholder="Mot de passe"
                            value={formData.password}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    {error && <p className="error-message">{error}</p>}
                    <button type="submit" className="login-button">Se connecter</button>
                </form>
            </div>
        </div>
    );
};

export default LoginPage;