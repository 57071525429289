import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

interface ProtectedRouteProps {
    children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
    const auth = useAuth();

    if (!auth.isAuthenticated) {
        // Si l'utilisateur n'est pas authentifié, rediriger vers la page de connexion
        return <Navigate to="/login" replace />;
    }

    // Si l'utilisateur est authentifié, afficher la route protégée
    return <>{children}</>;
};

export default ProtectedRoute;