import React from 'react';
import './TrackingPage.css';

interface FormData {
    childName: string;
    age: string;
    departureAddress: string;
    arrivalAddress: string;
    departureTime: string;
    arrivalTime: string;
    nameRefDepart: string;
    phoneRefDepart: string;
    phoneRefArriver: string;
    nameRefArriver: string;
    serviceName: string;
    comment: string;
    status: string;  // Champ pour le statut
}

const statusInFrench = (status: string) => {
    switch (status.toLowerCase()) {
        case 'arrived':
            return 'Remis';
        case 'shipped':
            return 'En_cours';
        case 'packaging':
            return 'Validé';
        case 'order placed':
            return 'Commande passée';
        case 'shipping':
            return 'En cours de livraison';
        default:
            return status;
    }
};

const TrackingPage: React.FC<{ formDataList: FormData[] }> = ({ formDataList }) => {
    return (
        <div className="tracking-page">
            <h1>Suivi des transports réservés</h1>
            <table className="tracking-table">
                <thead>
                <tr>
                    <th>Nom de l'enfant</th>
                    <th>Adresse de départ</th>
                    <th>Adresse d'arrivée</th>
                    <th>Heure de départ</th>
                    <th>Heure d'arrivée</th>
                    <th>Nom du service</th>
                    <th>Référent au départ</th>
                    <th>Référent à l'arrivée</th>
                    <th>Statut</th>
                </tr>
                </thead>
                <tbody>
                {formDataList.map((formData, index) => (
                    <tr key={index}>
                        <td>{formData.childName}</td>
                        <td>{formData.departureAddress}</td>
                        <td>{formData.arrivalAddress}</td>
                        <td>{formData.departureTime}</td>
                        <td>{formData.arrivalTime}</td>
                        <td>{formData.serviceName}</td>
                        <td>{formData.nameRefDepart}</td>
                        <td>{formData.nameRefArriver}</td>
                        <td>
                <span className={`status ${formData.status.toLowerCase()}`}>
                  {statusInFrench(formData.status)}
                </span>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default TrackingPage;