import React from 'react';
import {Link} from 'react-router-dom';
import './Sidebar.css';
import logo from "../assets/logo.jpeg";

const Sidebar: React.FC = () => {
    return (
        <div className="sidebar">
            <div className="sidebar-header">
                <img src={logo} alt="Logo" className="logo"/>
                <h2>AB Resa</h2>
            </div>
            <ul className="sidebar-menu">
                <li>
                    <Link to="/dashboard">
                        <i className="fa-solid fa-calendar"></i>
                        Réservation
                    </Link>
                </li>
                <li>
                    <Link to="/tracking">
                        <i className="fa-solid fa-chart-column"></i>
                        Statistiques
                    </Link>
                </li>
                <li>
                    <Link to="/tracking">
                        <i className="fa-solid fa-forward"></i>
                        Suivi
                    </Link>
                </li>
                <li>
                    <Link to="/tracking">
                        <i className="fa-solid fa-cloud-moon-rain"></i>
                        Incidents
                    </Link>
                </li>
                <li>
                    <Link to="/tracking">
                        <i className="fa-solid fa-circle-info"></i>
                        Aide
                    </Link>
                </li>
            </ul>
        </div>
    );
};

export default Sidebar;